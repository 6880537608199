class Modal {
    modalWrap = document.querySelector('.modal .info-text');
    body = document.querySelector('body');
    closeButtons = document.querySelectorAll('.modal .close-modal');
    data = {
        0: `<p>Selfies seem OK, but not all are good for a dating app.</p>
                <div class="table">
                    <div class="top">✨ Hily insight ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>🎭</span> Overly filtered or edited selfies turn away 33% of women and 41% of men</li>
                            <li><span>🚬</span> Around 25% of women and men aren't keen on smoking in profile pics</li>
                            <li><span>🦆</span> 23% of women and 18% of men prefer to skip the duck face selfies</li>
                            <li><span>🚽</span> 12% of women are not impressed by bathroom selfies</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Don’t post overly filtered selfies in the bathroom while doing a duck face :)</p>
                </div>`,
        1: `<p>Rush breaks the atmosphere, so make sure your chat is comfortable for both!</p>
                <div class="table">
                    <div class="top">✨ What to do instead:  ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>👋</span> Start with fun and flirty icebreakers</li>
                            <li><span>💬</span> To dive deeper, ask about their memories, interests and experiences</li>
                            <li><span>🌟</span> Discuss values when you both feel ready to strengthen your connection</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Don’t rush it! Start with light and fun lines, and then go deeper into values</p>
                </div>`,
        2: `<p>Good zodiac synastry is important for some Hily users, so don’t forget to mention it!</p>
                <div class="table">
                    <div class="top">✨ Hily insight ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>🌟</span> 41% of Hily users find astrological compatibility intriguing</li>
                            <li><span>💫</span> 37% of females prefer seeing zodiac signs in dating profiles</li>
                            <li><span>🥳</span> Almost 85% of women would not reject someone based on their zodiac sign and compatibility</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Mention your zodiac sign but don’t expect too much from it</p>
                </div>`,
        3: `<p>Family is important, but surely not when it comes to a dating app. You don’t want your future partner to think you’ve got mommy issues, right?</p>
                <div class="table">
                    <div class="top">✨ What to do instead:  ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>🤳</span> Use clear photo of just you on the first photo</li>
                            <li><span>🎨</span> Show off your hobbies and interests</li>
                            <li><span>🚫</span> Avoid big groups and family photos</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Only 4% of female users really like seeing a photo with your mom, while 22% prefer seeing other aspects of your life</p>
                </div>`,
        4: `<p>Group photos are great to showcase your social side. But are you sure people will like you there, and not a person next to you?</p>
                <div class="table">
                    <div class="top">✨ What to do instead:  ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>📸</span> Use a group photo if only it’s needed</li>
                            <li><span>🔍</span> Ensure your the brightest person on it</li>
                            <li><span>❌</span> Do not post photos with your ex-partner!</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>It’s your dating profile, so avoid putting someone else on your profile photo. If you still want this, go for a dog or a cat :)</p>
                </div>`,
        5: `<p>Your height sets clear expectations about your look, so be upfront about it!</p>
                <div class="table">
                    <div class="top">✨ Hily insight ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>📏</span> 70% of women think mentioning height is important</li>
                            <li><span>📐</span> 50% of men think mentioning height is important</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Be honest about your height to avoid unpleasant surprises on your first date</p>
                </div>`,
        6: `<p>Half-naked pics might send a ‘Not serious’ signal, so be careful with them.</p>
                <div class="table">
                    <div class="top">✨ Hily insight ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>🚫</span> 34% of women aren't really into half-naked pics</li>
                            <li><span>👍</span> Only 26% of men really like half-naked pics</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Go for safer profile pics that show your true self and interests beyond physical appearance</p>
                </div>`,
        7: `<p>You cannot repeat the first impression, so don’t spoil it with poor grammar!</p>
                <div class="table">
                    <div class="top">✨ Hily insight ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>📝</span> 30% of women admitted they get annoyed by mistakes in writing</li>
                            <li><span>👀</span> 5% of men wouldn’t go on a date with someone who is bad at spelling</li>
                            <li><span>🎈</span> Both genders would prefer someone who is fun to be around with</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Rely on compatibility yet double-check what you write to increase your dating chances</p>
                </div>`,
        8: `<p>Timing can be everything in online dating. How does response time impact interest and engagement?</p>
                <div class="table">
                    <div class="top">✨ Hily insight ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>🕒</span> 36% of men and 41% of women lose interest with slow responses</li>
                            <li><span>💔</span> 46% of men and 41% of women might lose interest if a date disappears and reappears</li>
                            <li><span>🥺</span> 26% of men and a significant 42% of women will definitely lose interest if a date disappears</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Reply quickly and keep your conversations flowing to maintain interest and connection!</p>
                </div>`,
        9: `<p>Good timing for setting a date is essential, so be bold yet respect their decision.</p>
                <div class="table">
                    <div class="top">✨ What to do instead:  ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>😔</span> Don’t let your fear of rejection block you from asking someone out</li>
                            <li><span>🤐</span> Set a date in a private setting to avoid pressure and be clear about your intentions</li>
                            <li><span>🙏</span> Respect a ‘no’ for an answer</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>While setting a date, be clear about your feelings and open to discuss the plan</p>
                </div>`,
        10: `<p>If you cannot recall anything about them, your first date went wrong.</p>
                <div class="table">
                    <div class="top">✨ What to do instead:  ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>🤔</span> Exchange questions to show interest and get to know each other</li>
                            <li><span>🙅</span> Notice shutting down expressions to keep conversation open</li>
                            <li><span>❤️</span> Ensure you have same relationship goals and commitment expectations</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Stay present, check red flags, and be ready to learn more about your date</p>
                </div>`,
        11: `<p>Love bombing, or overwhelming adoration early in a relationship, is a tricky red flag.</p>
                <div class="table">
                    <div class="top">✨ What to do instead:  ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>💥</span> Notice whenever their affection seems not genuine and rushed</li>
                            <li><span>❤️</span> Trust your feelings and be yourself; don't feel forced to love them back</li>
                            <li><span>🔒</span> Keep your space. Don't let anyone keep you away from friends and family</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Listen to your feelings, stay connected with your people, and let your affection grow steadily</p>
                </div>`,
        12: `<p>Navigating the first move in online dating can be a game of guesswork. Who should make the first move?</p>
                <div class="table">
                    <div class="top">✨ Hily insight ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>🚺</span> A striking 81% of women prefer men to take the lead and reach out first</li>
                            <li><span>🚹</span> Meanwhile, 67% of men are really into it when women make the first move</li>
                            <li><span>💁‍</span> Mixed Signals: almost half of the women do reach out first sometimes</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Don't be afraid to break traditional norms and go with what feels right for you!</p>
                </div>`,
        13: `<p>Online dating looks like competition these days, that’s why you should maintain your fidelity.</p>
                <div class="table">
                    <div class="top">✨ What to do instead:  ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>🌱</span> Leave a chat if you feel stressed or overwhelmed</li>
                            <li><span>📱</span> Take a break from dating if swiping and bad dates feel exhausting</li>
                            <li><span>🎧</span> Trust your feelings more than words from people around</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Prioritize your well-being so your dating experience remains positive and enjoyable for you</p>
                </div>`,
        14: `<p>Arguing is non-romantic, so talk openly about who pays to set a comfortable tone on a date.</p>
                <div class="table">
                    <div class="top">✨ Hily insight ✨</div>
                    <div class="content">
                        <ul>
                            <li><span>💸</span> 44.6% of men feel responsible to pay for dinner, because it’s traditional</li>
                            <li><span>🔄</span> 43.7% of women believe whoever initiates the date should pay</li>
                        </ul>
                    </div>
                </div>
                <div class="excerpt">
                    <div class="small-hearts">
                        <img src="img/small_hearth_1.svg" class="hearth_m_1" alt="">
                        <img src="img/small_hearth_2.svg" class="hearth_m_2" alt="">
                    </div>
                    <p>Pick an affordable first date spot that will consider your budgets, like coffee or a walk</p>
                </div>`,
    }

    constructor(options) {
        this.selectors = document.querySelectorAll(options.selectors);
        this.init();
    }

    init() {
        this.openModal();
        this.closeModal();

        this.selectors.forEach((selector) => {
            let attr = selector.getAttribute('data-modal');
            selector.addEventListener('click', (e) => this.openModal(attr, e));
        });

        this.closeButtons.forEach((closeButton) => {
            let attr = closeButton.getAttribute('data-modal');
            closeButton.addEventListener('click', () => this.closeModal(attr));
        });
    }

    openModal(attr, e) {
        if (attr) {
            let index = document.querySelector('.swiper-slide.swiper-slide-active')
                ? document.querySelector('.swiper-slide.swiper-slide-active').getAttribute('data-swiper-slide-index')
                : e.target.getAttribute('data-id');
            this.modalWrap.innerHTML = this.data[index];

            this.body.classList.add('open__modal');
            setTimeout(() => {
                document.querySelector(attr).classList.add('open__modal__window');
            }, 350);

            setTimeout(() => {
                document.querySelector('.modal').classList.remove('loading');
            }, 1500);
        }
    }

    closeModal(attr) {
        if (attr) {
            document.querySelector(attr).classList.remove('open__modal__window');
            setTimeout(() => {
                this.body.classList.remove('open__modal');
            }, 350);

            setTimeout(() => {
                document.querySelector('.modal').classList.add('loading');
            }, 1000);
        }
    }
}