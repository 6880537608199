console.log('init');

function createStars(count) {
    for (let i = 1; i <= count; i++) {
        drawStars();
    }
}

function drawStars(){
    let tmpStar = document.createElement('figure')
    tmpStar.className = 'star';
    tmpStar.style.top = 100 * Math.random() + '%';
    tmpStar.style.left = 100 * Math.random() + '%';
    document.getElementById('stars').appendChild(tmpStar);
}

createStars(100);

function animateStars() {
    let stars = document.querySelectorAll(".star");
    Array.prototype.forEach.call(stars, function(el, i){
        TweenMax.to(el, Math.random() * 0.5 + 0.5, { opacity: Math.random(), onComplete: animateStars });
    });
}

animateStars();

function initSwiper() {
    const swiper = new Swiper('.mySwiper', {
        // slidesPerView: 4,
        spaceBetween: 50,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    document.querySelector('.buttons .next_slide').addEventListener('click', () => {
        swiper.slideNext()
    });
}

if (window.innerWidth > 767) {
    initSwiper()
}

// window.addEventListener('resize', () => {
//     if (window.innerWidth < 768 && typeof(swiper) !== "undefined") {
//         swiper.destroy();
//     } else if (window.innerWidth > 767 && typeof(swiper) === "undefined") {
//         initSwiper();
//     }
// });

let modal = new Modal({
    selectors: '.open_modal',
});

document.querySelector('.hamburger').addEventListener('click', () => {
    if (document.querySelector('body').classList.contains('opened')) {
        document.querySelector('body').classList.remove('opened');
    } else {
        document.querySelector('body').classList.add('opened');
    }
});

let mobileSkipButtons = document.querySelectorAll('.mobile-buttons .next_slide');
mobileSkipButtons.forEach((mobileSkipButton) => {
    let id = mobileSkipButton.getAttribute('data-id');
    mobileSkipButton.addEventListener('click', () => animateAnchor(id));
});

function animateAnchor (id) {
    let block = document.querySelector('.swiper-slide.item-' + id);
    window.scrollTo({top: block.offsetTop + 280, behavior: 'smooth'});
}

let bottomAnchor = document.querySelector('.to_bottom');
bottomAnchor.addEventListener('click', () => {
    window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
    // window.scrollTo({top: position, behavior: 'smooth'});
});